import NewsletterForm from '@theme/components/shop/NewsletterForm'

export default {
  components: {
    NewsletterForm,
  },
  computed: {
    openingHours() {
      return (this.$store.state.globals.block_contact || {}).store_opening_hours_short
    },
  },
}
